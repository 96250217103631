import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cc65707"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "WatFocusTask" }
const _hoisted_2 = { class: "WatFocusTask--Header" }
const _hoisted_3 = { class: "Modal--Info" }
const _hoisted_4 = { class: "Modal--Actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatFocusWelcomePage = _resolveComponent("WatFocusWelcomePage")!
  const _component_WatFocusTesterCalendar = _resolveComponent("WatFocusTesterCalendar")!
  const _component_WatFocusConectivityTest = _resolveComponent("WatFocusConectivityTest")!
  const _component_WatFocusTesterInfo = _resolveComponent("WatFocusTesterInfo")!
  const _component_ModalBasic = _resolveComponent("ModalBasic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.testTitle), 1),
      _createElementVNode("h1", null, _toDisplayString(_ctx.pageTitle), 1),
      _createElementVNode("h2", null, _toDisplayString(_ctx.pageInfo), 1)
    ]),
    (_ctx.showWatFocusWelcome || _ctx.showWelcomeFinished)
      ? (_openBlock(), _createBlock(_component_WatFocusWelcomePage, {
          key: 0,
          publicFinalText: _ctx.publicFinalText,
          publicFinalTitle: _ctx.publicFinalTitle,
          data: _ctx.watFocusConfig.welcomePage,
          showFinishedPage: _ctx.showWelcomeFinished,
          onAccept: _cache[0] || (_cache[0] = ($event: any) => (_ctx.welcomePageFinish()))
        }, null, 8, ["publicFinalText", "publicFinalTitle", "data", "showFinishedPage"]))
      : _createCommentVNode("", true),
    (_ctx.showCalendar)
      ? (_openBlock(), _createBlock(_component_WatFocusTesterCalendar, {
          key: 1,
          slots: _ctx.watFocusConfig.slots,
          ranges: _ctx.watFocusConfig.ranges,
          onCompleted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmCalendarDates($event))),
          sessionMinutes: _ctx.watFocusConfig.sessionMinutes
        }, null, 8, ["slots", "ranges", "sessionMinutes"]))
      : _createCommentVNode("", true),
    (_ctx.showConnectivity)
      ? (_openBlock(), _createBlock(_component_WatFocusConectivityTest, {
          key: 2,
          isAndroid: _ctx.isAndroid,
          isNativePlatform: _ctx.isNativePlatform,
          showScreenShare: _ctx.showScreenShare,
          onCompleted: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveConectivityTest($event)))
        }, null, 8, ["isAndroid", "isNativePlatform", "showScreenShare"]))
      : _createCommentVNode("", true),
    (_ctx.showTesterInfo)
      ? (_openBlock(), _createBlock(_component_WatFocusTesterInfo, {
          key: 3,
          provinces: _ctx.provinces,
          onCompleted: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveTesterInfo($event))),
          onBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.backToCalendar()))
        }, null, 8, ["provinces"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ModalBasic, {
      class: "Modal",
      show: _ctx.showModal,
      onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('cancel-advert')))
    }, {
      content: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('wat_focus_advert_title')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wat_focus_advert_info')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t('wat_focus_advert_important_title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wat_focus_advert_important_info')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('cancel-advert')))
          }, _toDisplayString(_ctx.$t('wat_focus_advert_cancel')), 1),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.advertAccepted()))
          }, _toDisplayString(_ctx.$t('wat_focus_advert_go_to_test')), 1)
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}