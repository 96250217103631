<template lang="html">
    <transition name="modal" v-if="show">
        <div class="ModalBasic">
          <div class="ModalBasic--Box">
            <i v-on:click="$emit('close')">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L1 13M1 1L13 13" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </i>
            <div class="ModalBasic--Content">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'ModalBasic',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="scss" scoped>
  .ModalBasic {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .6);
    backdrop-filter: blur(.3rem);
    display: table;
    transition: opacity .3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;
    }
    &--Box{
      display: flex;
      padding: 48px;
      gap: 24px;
      border-radius: 20px;
      background: white;
      position: relative;
    }
    &--Content {
 
    }
  }
  </style>
